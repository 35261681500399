/**
 * @typedef {Object} EmailGroupTags - An enum-like object of group tags for email communication
 * @property {'auth'} AUTH
 * @property {'access_notice'} ACCESS_NOTICE
 * @property {'billing'} BILLING
 * @property {'internal'} INTERNAL
 * @property {'job_sharing'} JOB_SHARING
 * @property {'photo_uploads'} PHOTO_UPLOADS
 * @property {'photo_uploads_critical'} PHOTO_UPLOADS_CRITICAL
 * @property {'portal_admin'} PORTAL_ADMIN
 * @property {'app_status'} APP_STATUS
 * @property {'app_status_critical'} APP_STATUS_CRITICAL
 * @property {'survey_data'} SURVEY_DATA
 * @property {'legacy'} LEGACY
 */

/**
 * @type {EmailGroupTags}
 */
export const EMAIL_GROUP_TAGS = {
  AUTH: 'auth',
  ACCESS_NOTICE: 'access_notice',
  BILLING: 'billing',
  INTERNAL: 'internal',
  JOB_SHARING: 'job_sharing',
  PHOTO_UPLOADS: 'photo_uploads',
  PHOTO_UPLOADS_CRITICAL: 'photo_uploads_critical',
  PORTAL_ADMIN: 'portal_admin',
  APP_STATUS: 'app_status',
  APP_STATUS_CRITICAL: 'app_status_critical',
  SURVEY_DATA: 'survey_data',
  LEGACY: 'legacy'
};

/**
 * @typedef {EmailGroupTags[keyof EmailGroupTags]} EmailGroupTag - A valid group tag string value for email communication
 */

/**
 * @typedef {Array.<EmailGroupTag>} CommunicationOptOutList - A list of group tags the user has opted out of for a specific communication type
 */

/**
 * @typedef {"email"} CommunicationType - Valid communication types
 */

/**
 * @typedef {Object} UserCommunicationPreferences
 * @property {Object} email - The email communication preferences
 * @property {CommunicationOptOutList} email.opt_out_list - The list of group tags the user has opted out of for emails
 */

/**
 * Returns the user's communication preferences object
 * @param {string} userEmailOrId - The user's email or user id. If you are using the client side firebase, you must pass in a user id.
 * @param {object} [options] - The options object
 * @param {object} [options.firebase] - The firebase instance to use. If not provided, will use the global FirebaseWorker instance.
 * @returns {Promise<UserCommunicationPreferences?>}
 */
export async function GetUserCommunicationPreferences(userEmailOrId, options) {
  const firebase = _getFirebase(options?.firebase);
  const preferencesPath = await _getCommunicationPreferencePathForUser(userEmailOrId, options);
  if (!preferencesPath) return null;
  return (await firebase.database().ref(preferencesPath).once('value')).val();
}

/**
 * Returns an array of group tags the user has opted out of for a specific communication type
 * @param {string} userEmailOrId - The user's email or user id. If you are using the client side firebase, you must pass in a user id.
 * @param {CommunicationType} communicationType - The communication type to get the opt out list for
 * @param {object} [options] - The options object
 * @param {object} [options.firebase] - The firebase instance to use. If not provided, will use the global FirebaseWorker instance.
 * @returns {Promise<CommunicationOptOutList>}
 */
export async function GetUserCommunicationOptOutList(userEmailOrId, communicationType, options) {
  const firebase = _getFirebase(options?.firebase);
  _validateCommunicationType(communicationType);
  const preferencesPath = await _getCommunicationPreferencePathForUser(userEmailOrId, options);
  if (!preferencesPath) return [];
  const optOutListPath = `${preferencesPath}/${communicationType}/opt_out_list`;
  return (await firebase.database().ref(optOutListPath).once('value')).val() || [];
}

/**
 * Updates a user's opt out list for a specific communication type
 * @param {string} userEmailOrId - The user's email or user id. If you are using the client side firebase, you must pass in a user id.
 * @param {CommunicationType} communicationType - The communication type to update the opt out list for
 * @param {Object.<EmailGroupTag, Boolean>} groupTags - An object of group tags to update the opt out list with. The key is the group tag
 * and the value is a boolean indicating whether to add or remove the tag from the opt out list.
 * @param {object} [options] - The options object
 * @param {object} [options.firebase] - The firebase instance to use. If not provided, will use the global FirebaseWorker instance.
 * @returns {Promise<void>}
 */
export async function UpdateUserCommunicationOptOutList(userEmailOrId, communicationType, groupTags, options) {
  const firebase = _getFirebase(options?.firebase);
  _validateCommunicationType(communicationType);

  const preferencesPath = await _getCommunicationPreferencePathForUser(userEmailOrId, options);
  // If there is no preferences path, don't update
  if (!preferencesPath) return;

  const optOutList = await GetUserCommunicationOptOutList(userEmailOrId, communicationType, options);

  // Loop through the group tags and add or remove them from the opt out list
  for (const tag in groupTags) {
    // Make sure the tag is valid
    _validateGroupTag(tag);
    const tagIndex = optOutList.indexOf(tag);
    const tagExists = tagIndex != -1;
    // If the flag true and it does not exist already, add it to the list. Otherwise,
    // if the flag is not true and the tag exists, remove it from the list.
    if (groupTags[tag] === true) {
      if (!tagExists) optOutList.push(tag);
    } else if (tagExists) {
      optOutList.splice(tagIndex, 1);
    }
  }

  await firebase.database().ref(`${preferencesPath}/${communicationType}/opt_out_list`).set(optOutList);
}

/**
 * Returns whether a user has opted out of a specific group tag for a specific communication type
 * @param {string} userEmailOrId - The user's email or user id. If you are using the client side firebase, you must pass in a user id.
 * @param {CommunicationType} communicationType - The communication type to check the opt out list for
 * @param {EmailGroupTag} groupTag - The group tag to check if the user has opted out of
 * @param {object} [options] - The options object
 * @param {object} [options.firebase] - The firebase instance to use. If not provided, will use the global FirebaseWorker instance.
 * @returns {Promise<Boolean>} - Whether the user has opted out of the group tag
 */
export async function IsUserOptedOutOfCommunicationGroupTag(userEmailOrId, communicationType, groupTag, options) {
  _validateCommunicationType(communicationType);
  const optOutList = await GetUserCommunicationOptOutList(userEmailOrId, communicationType, options);
  return optOutList.includes(groupTag);
}

/**
 * Helper function to get the path for a user's communication preferences
 * @param {string} userEmailOrId - The user's email or user id. If you are using the client side firebase, you must pass in a user id.
 * @param {object} [options] - The options object
 * @param {object} [options.firebase] - The firebase instance to use. If not provided, will use the global FirebaseWorker instance
 * @returns {Promise<string?>} - The path to the user's communication preferences or null if a path cannot be determined.
 */
async function _getCommunicationPreferencePathForUser(userEmailOrId, options) {
  const firebase = _getFirebase(options?.firebase);
  const firebaseIsClientSDK = !!firebase.auth().currentUser;
  const userEmailOrIdIsEmail = userEmailOrId.includes('@');

  // If we are using the client side firebase, we need to make sure userEmailOrId is a userId
  if (firebaseIsClientSDK && userEmailOrIdIsEmail) {
    throw new Error(`You must pass in a user id on the client side. Got "${userEmailOrId}" instead.`);
  }

  // Assume userEmailOrId is a userId
  let userId = userEmailOrId;
  // Check if userEmailOrId is an email address
  if (userEmailOrIdIsEmail) {
    // Fetch the user's id from user_info
    const userInfoRecord = (await firebase.database().ref(`user_info`).orderByChild('email').equalTo(userEmailOrId).once('value'))?.val();
    userId = Object.keys(userInfoRecord || {})[0];
  }
  if (!userId) return null;
  let rootCompany = (await firebase.database().ref(`user_groups/${userId}/root_company`).once('value')).val();
  if (!rootCompany) return null;
  return `users/${rootCompany}/${userId}/preferences/communication`;
}

/**
 * Resolves the passed in firebase instance or the global FirebaseWorker instance
 * @param {object} [firebase] - The firebase instance to use. If not provided, will return the global FirebaseWorker instance.
 * @returns {object} - The firebase instance to use
 */
function _getFirebase(firebase) {
  return firebase || globalThis.FirebaseWorker;
}

/**
 * Checks that the communication type is a valid value and throws an error if not
 * @param {CommunicationType} communicationType - The communication type to validate
 * @returns {void}
 */
function _validateCommunicationType(communicationType) {
  if (!['email'].includes(communicationType)) throw new Error(`Invalid communication type "${communicationType}".`);
}

/**
 * Checks that the group tag is a valid value and throws an error if not
 * @param {EmailGroupTag} groupTag - The group tag to validate
 * @returns {void}
 */
function _validateGroupTag(groupTag) {
  if (!Object.values(EMAIL_GROUP_TAGS).includes(groupTag)) throw new Error(`Invalid group tag "${groupTag}".`);
}
