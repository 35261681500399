import { Path } from './Path.js';
import { Convert } from './Convert.js';
import { Round } from './Round.js';
import { DateTime } from 'luxon';

/**
 * @typedef {Object} KLogicOperator
 * @property {string} name - The name of the operator
 * @property {string} description - A description of the operator's function
 * @property {function} evaluate - A function that takes an array of callable arguments and returns the result of the operation
 * @property {string} returnType - The type of the result of the operation
 * @property {string[]} argumentTypes - The types of the operator's arguments
 * @property {boolean} [isChainable] - Whether the operator can be chained with other operators
 * @property {boolean} [hasItems] - Whether the operator's arguments are list items
 * @property {boolean} [hasIntermediate] - Whether the operator has an intermediate result
 * @property {boolean} [deprecated] - Whether the operator is deprecated
 */

/**
 * A collection of KLogic operators, each with a name, description, and evaluation function.
 * @type {Object.<string, KLogicOperator>}
 */
export const KLogicOperators = {
  // Get (treat arg2 as a path to get from arg1)
  GET_FROM: {
    name: 'Get From',
    description: 'Treats arg-1 as a data source and arg-2 as a path to get from that source.',
    evaluate: (args) => {
      const path = args[1]();
      return path != null ? Path.get(args[0](), path) : null;
    },
    returnType: 'any',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['object', 'text']
  },

  // Exists
  EXISTS: {
    name: 'Exists',
    description: 'Whether arg-1 exists (is any value besides null, undefined, or empty text).',
    evaluate: (args) => ![null, undefined, ''].includes(args[0]()),
    returnType: 'boolean',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['any']
  },

  // Does Not Exist
  NOT_EXISTS: {
    name: 'Does Not Exist',
    description: 'Whether arg-1 does not exists (is either null, undefined, or empty text).',
    evaluate: (args) => [null, undefined, ''].includes(args[0]()),
    returnType: 'boolean',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['any']
  },

  // Is False
  IS_FALSE: {
    name: 'Is False',
    description: 'Whether arg-1 is (or is considered) false.',
    evaluate: (args) => Boolean(args[0]()) === false,
    returnType: 'boolean',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['any']
  },

  // Is True
  IS_TRUE: {
    name: 'Is True',
    description: 'Whether arg-1 is (or is considered) true.',
    evaluate: (args) => Boolean(args[0]()) === true,
    returnType: 'boolean',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['any']
  },

  // Logical AND (chainable)
  AND: {
    name: 'Logical And',
    description: 'Logical "and" (all arguments are true).',
    evaluate: (args) => args.every((x) => x()),
    returnType: 'boolean',
    isChainable: true,
    hasItems: false,
    argumentTypes: ['any']
  },

  // Logical OR (chainable)
  OR: {
    name: 'Logical Or',
    description: 'Logical "or" (at least one argument is true).',
    evaluate: (args) => args.some((x) => x()),
    returnType: 'boolean',
    isChainable: true,
    hasItems: false,
    argumentTypes: ['any']
  },

  // Equality
  EQUAL: {
    name: 'Equal',
    description: 'Whether arg-1 has the same value as arg-2.',
    evaluate: (args) => args[0]() == args[1](),
    returnType: 'boolean',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['any', 'any']
  },

  // Inequality
  NOT_EQUAL: {
    name: 'Not Equal',
    description: 'Whether arg-1 does not have the same value as arg-2.',
    evaluate: (args) => args[0]() != args[1](),
    returnType: 'boolean',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['any', 'any']
  },

  // Greater than
  GREATER_THAN: {
    name: 'Greater Than',
    description: 'Whether arg-1 is numerically greater than arg-2.',
    evaluate: (args) => Number(args[0]()) > Number(args[1]()),
    returnType: 'boolean',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['any', 'any']
  },

  // Less than
  LESS_THAN: {
    name: 'Less Than',
    description: 'Whether arg-1 is numerically less than arg-2.',
    evaluate: (args) => Number(args[0]()) < Number(args[1]()),
    returnType: 'boolean',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['any', 'any']
  },

  // Greater than or equal to
  GREATER_OR_EQUAL: {
    name: 'Greater Than or Equal',
    description: 'Whether arg-1 is numerically greater than or equal to arg-2.',
    evaluate: (args) => Number(args[0]()) >= Number(args[1]()),
    returnType: 'boolean',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['any', 'any']
  },

  // Less than or equal to
  LESS_OR_EQUAL: {
    name: 'Less Than or Equal',
    description: 'Whether arg-1 is numerically less than or equal to arg-2.',
    evaluate: (args) => Number(args[0]()) <= Number(args[1]()),
    returnType: 'boolean',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['any', 'any']
  },

  // Nullish coalescing
  FALLBACK: {
    name: 'Fallback',
    description: 'Returns arg-1 unless it is null or undefined, where it returns arg-2.',
    evaluate: (args) => args[0]() ?? args[1](),
    returnType: 'any',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['any', 'any']
  },

  // Ternary operation
  IF_ELSE: {
    name: 'If-Else',
    description: 'If arg-1 (a condition) is true, returns arg-2, otherwise returns arg-3.',
    evaluate: (args) => (args[0]() ? args[1]() : args[2]()),
    returnType: 'any',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['expression', 'any', 'any']
  },

  // Mathematical summation (chainable)
  SUM: {
    name: 'Sum',
    description: 'Returns the sum of all the arguments.',
    evaluate: (args) => args.reduce((acc, x) => acc + Number(x()), 0),
    returnType: 'number',
    isChainable: true,
    hasItems: false,
    argumentTypes: ['number']
  },

  // Sum list items
  SUM_LIST: {
    name: 'Sum List',
    description: `Returns the sum of arg-1's list items.`,
    evaluate: (args) => args[0]().reduce((acc, x) => acc + Number(x), 0),
    returnType: 'number',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['list']
  },

  // Mathematical subtraction
  SUBTRACT: {
    name: 'Subtract',
    description: 'Subtracts arg-2 from arg-1.',
    evaluate: (args) => Number(args[0]()) - Number(args[1]()),
    returnType: 'number',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['number', 'number']
  },

  // List / String inclusion
  INCLUDES: {
    name: 'Includes',
    description: 'Whether arg-2 is present in arg-1 (which can be either a list or text).',
    evaluate: (args) => args[0]()?.includes(args[1]()),
    returnType: 'boolean',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['list/text', 'expression']
  },

  // List / String non-inclusion
  EXCLUDES: {
    name: 'Excludes',
    description: 'Whether arg-2 is not present in arg-1 (which can be either a list or text).',
    evaluate: (args) => !args[0]()?.includes(args[1]()),
    returnType: 'boolean',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['list/text', 'expression']
  },

  // List some
  SOME: {
    name: 'Some List Item Is',
    description: `Whether at least one item in the arg-1 list meets arg-2's condition.`,
    evaluate: (args) => args[0]()?.some((x, i, a) => args[1]({ x, i, a })),
    returnType: 'boolean',
    isChainable: false,
    hasItems: true,
    argumentTypes: ['list', 'expression']
  },

  // List every
  EVERY: {
    name: 'Every List Item Is',
    description: `Whether every item in the arg-1 list meets arg-2's condition.`,
    evaluate: (args) => args[0]()?.every((x, i, a) => args[1]({ x, i, a })),
    returnType: 'boolean',
    isChainable: false,
    hasItems: true,
    argumentTypes: ['list', 'expression']
  },

  // List filter
  FILTER: {
    name: 'Filter List',
    description: `Returns a subset of arg-1's list items that satisfy arg-2's condition.`,
    evaluate: (args) => args[0]()?.filter((x, i, a) => args[1]({ x, i, a })),
    returnType: 'list',
    isChainable: false,
    hasItems: true,
    argumentTypes: ['list', 'expression']
  },

  // List filter for unique items
  UNIQUE: {
    name: 'Filter Unique',
    description: `Returns a list of unique values from arg-1's list items.`,
    evaluate: (args) => [...new Set(args[0]())],
    returnType: 'list',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['list']
  },

  // List / text length
  LENGTH: {
    name: 'Count',
    description: `Counts the number of list items in arg-1 (or the number of characters if arg-1 is text).`,
    evaluate: (args) => Number(args[0]()?.length),
    returnType: 'number',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['list/text']
  },

  // Array count if
  COUNT: {
    name: 'Count If',
    description: `Counts the number of arg-1's list items that satisfy arg-2's condition.`,
    evaluate: (args) => args[0]()?.filter((x, i, a) => args[1]({ x, i, a }))?.length,
    returnType: 'number',
    isChainable: false,
    hasItems: true,
    argumentTypes: ['list', 'expression']
  },

  // Array find
  FIND: {
    name: 'Find In List',
    description: `Returns the first list item from arg-1 that satisfies arg-2's condition.`,
    evaluate: (args) => args[0]()?.find((x, i, a) => args[1]({ x, i, a })),
    returnType: 'any',
    isChainable: false,
    hasItems: true,
    argumentTypes: ['list', 'expression']
  },

  // Make list (map args to array)
  MAKE_LIST: {
    name: 'Make List From Args',
    description: `Creates a list from the provided arguments.`,
    evaluate: (args) => args.map((x) => x()),
    returnType: 'list',
    isChainable: true,
    hasItems: false,
    argumentTypes: ['any']
  },

  // Make lookup
  MAKE_LOOKUP: {
    name: 'Make Lookup From Args',
    description: `Creates a lookup from the provided arguments (each a 2-item list, key-value pairs).`,
    evaluate: (args) => Object.fromEntries(args.map((x) => x()).filter((x) => x)),
    returnType: 'object',
    isChainable: true,
    hasItems: false,
    argumentTypes: ['list']
  },

  // Array map
  MAP: {
    name: 'Map List',
    description: `Maps each of arg-1's list items to a new list by applying the arg-2 expression to each.`,
    evaluate: (args) => args[0]()?.map((x, i, a) => args[1]({ x, i, a })),
    returnType: 'list',
    isChainable: false,
    hasItems: true,
    argumentTypes: ['list', 'expression']
  },

  // Join (merge array with delim)
  JOIN: {
    name: 'Join List',
    description:
      `Joins each of arg-1's list items with a delmiter (optionally specified by the arg-2). ` +
      `If no delimiter is specified, the list's items will be concatenated together with nothing in between.`,
    evaluate: (args) => args[0]()?.join(args[1]() ?? ''),
    returnType: 'string',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['list', 'text']
  },

  // Join (merge array with delim)
  SPLIT: {
    name: 'Split Text',
    description:
      `Splits text in arg-1 into a list on a delmiter (optionally specified by the arg-2). ` +
      `If no delimiter is specified, the text will be split character-by-character.`,
    evaluate: (args) => args[0]()?.split(args[1]() ?? ''),
    returnType: 'list',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['text', 'text']
  },

  // Keys (get an array of keys for an object)
  LIST_KEYS: {
    name: 'List Object Keys',
    description: `Returns a list of the object keys from arg-1.`,
    evaluate: (args) => Object.keys(args[0]() ?? {}),
    returnType: 'list',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['object']
  },

  // Values (get an array of values for an object)
  LIST_VALUES: {
    name: 'List Object Values',
    description: `Returns a list of the object values from arg-1.`,
    evaluate: (args) => Object.values(args[0]() ?? {}),
    returnType: 'list',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['object']
  },

  // Convert
  CONVERT: {
    deprecated: true, // Deprecated ops no longer appear in the editor
    name: 'Convert (Deprecated)',
    description: `Performs a unit conversion on arg-1 as defined by a list of parameters in arg-2.`,
    /** @ts-ignore */
    evaluate: (args) => Convert(args[0](), ...(args[1]() || [])),
    returnType: 'number',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['number', 'list']
  },

  CONVERT_UNITS: {
    name: 'Convert Units',
    description: 'Performs a unit conversion on arg-1; arg-2 as the source unit, arg-3 as the target, and arg-4 as the precision.',
    evaluate: (args) => Convert(args[0](), args[1](), args[2](), args[3](), true),
    returnType: 'number',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['number', 'text', 'text', 'any']
  },

  FORMAT_TIMESTAMP: {
    name: 'Format Timestamp',
    description:
      'Formats an epoch time in milliseconds in arg-1 to the format described in arg-2, using these format tokens: https://moment.github.io/luxon/#/formatting?id=table-of-tokens',
    evaluate: (args) => DateTime.fromMillis(args[0]()).toFormat(args[1]()),
    returnType: 'text',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['number', 'text']
  },

  // String concatenation (chainable)
  CONCATENATE: {
    name: 'Concatenate',
    description: `Concatenates all arguments to form a single string of text.`,
    evaluate: (args) => args.reduce((acc, x) => acc + (x()?.toString() ?? ''), ''),
    returnType: 'text',
    isChainable: true,
    hasItems: false,
    argumentTypes: ['text']
  },

  // Text pad start
  PAD_START: {
    name: 'Pad Start',
    description: `Pads the beginning of arg-1 to be a length given in arg-2 with a string given in arg-3 (defaulting to space).`,
    evaluate: (args) => args[0]()?.toString()?.padStart(args[1](), args[2]()),
    returnType: 'text',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['text', 'number', 'text']
  },

  // Text pad end
  PAD_END: {
    name: 'Pad End',
    description: `Pads the end of arg-1 to be a length given in arg-2 with a string given in arg-3 (defaulting to space).`,
    evaluate: (args) => args[0]()?.toString()?.padEnd(args[1](), args[2]()),
    returnType: 'text',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['text', 'number', 'text']
  },

  // Mathematical multiplication (chainable)
  MULTIPLY: {
    name: 'Multiply',
    description: 'Returns the product of all the arguments.',
    evaluate: (args) => (args.length ? args.reduce((acc, x) => acc * Number(x()), 1) : 0),
    returnType: 'number',
    isChainable: true,
    hasItems: false,
    argumentTypes: ['number']
  },

  // Mathematical division
  DIVIDE: {
    name: 'Divide',
    description: 'Divides arg-1 by arg-2.',
    evaluate: (args) => Number(args[0]()) / Number(args[1]()),
    returnType: 'number',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['number', 'number']
  },

  // Round to precision
  ROUND: {
    name: 'Round',
    description: `Rounds arg-1 to to the number of decimal places specified by arg-2 (defaults to 0).`,
    evaluate: (args) => Round(args[0](), args[1]()),
    returnType: 'number',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['number', 'number']
  },

  // List concatenation (chainable)
  CONCATENATE_LISTS: {
    name: 'Concatenate Lists',
    description: `Concatenates all arguments (usually lists) to form a single, flat list.`,
    evaluate: (args) => [].concat(...args.map((x) => x())),
    returnType: 'list',
    isChainable: true,
    hasItems: false,
    argumentTypes: ['list']
  },

  // Object concatenation (chainable)
  CONCATENATE_LOOKUPS: {
    name: 'Concatenate Lookups',
    description: `Merges all arguments (objects) to form a new single object.`,
    evaluate: (args) => Object.assign({}, ...args.map((x) => x())),
    returnType: 'object',
    isChainable: true,
    hasItems: false,
    argumentTypes: ['object']
  },

  // Creates a new array with all sub-array elements concatenated into it
  FLATTEN_LIST: {
    name: 'Flatten List',
    description: `Returns a new list with all sub-array elements concatenated into it recursively up to the specified depth.`,
    evaluate: (args) => args[0]().flat(args[1]() || 0),
    returnType: 'list',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['list', 'number']
  },

  // Round down
  FLOOR: {
    name: 'Floor',
    description: 'Rounds arg-1 down to the nearest integer.',
    evaluate: (args) => Math.floor(args[0]()),
    returnType: 'number',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['number']
  },

  // Round up
  CEIL: {
    name: 'Ceiling',
    description: 'Rounds arg-1 up to the nearest integer.',
    evaluate: (args) => Math.ceil(args[0]()),
    returnType: 'number',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['number']
  },

  // Maximum
  MAX: {
    name: 'Maximum',
    description: 'Returns the largest of all the arguments.',
    evaluate: (args) => Math.max(...args.map((x) => x())),
    returnType: 'number',
    isChainable: true,
    hasItems: false,
    argumentTypes: ['number']
  },

  // Minimum
  MIN: {
    name: 'Minimum',
    description: 'Returns the smallest of all the arguments.',
    evaluate: (args) => Math.min(...args.map((x) => x())),
    returnType: 'number',
    isChainable: true,
    hasItems: false,
    argumentTypes: ['number']
  },

  // Maximum of list
  MAX_LIST: {
    name: 'Maximum List Item',
    description: 'Returns the largest item in arg-1.',
    evaluate: ([arg1]) => {
      const list = arg1();
      return !list || list.length == 0 ? null : Math.max(...list);
    },
    returnType: 'number',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['list']
  },

  // Minimum of list
  MIN_LIST: {
    name: 'Minimum List Item',
    description: 'Returns the smallest item in arg-1.',
    evaluate: ([arg1]) => {
      const list = arg1();
      return !list || list.length == 0 ? null : Math.min(...list);
    },
    returnType: 'number',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['list']
  },

  // Reduce
  REDUCE: {
    name: 'Reduce List',
    description:
      'Reduces the list arg-1 to a single value by applying the arg-2 expression to each item and storing it as an ' +
      'intermediate value, with arg-3 as the initial intermediate value.',
    evaluate: ([arg1, arg2, arg3]) => arg1().reduce((acc, x, i, a) => (acc = arg2({ acc, x, i, a })), arg3()),
    returnType: 'any',
    isChainable: false,
    hasItems: true,
    hasIntermediate: true,
    argumentTypes: ['list', 'expression', 'any']
  },

  // Find and Replace
  REPLACE: {
    name: 'Find and Replace',
    description: 'Finds all instances of arg-2 in arg-1 and replaces them the value of arg-3.',
    evaluate: ([arg1, arg2, arg3]) => arg1()?.toString()?.replaceAll(arg2(), arg3()),
    returnType: 'text',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['text', 'text', 'text']
  },

  // To Uppercase
  TO_UPPER_CASE: {
    name: 'To Upper Case',
    description: 'Makes every character in arg-1 upper case.',
    evaluate: ([arg1]) => arg1()?.toString()?.toUpperCase(),
    returnType: 'text',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['text']
  },

  // To Lower case
  TO_LOWER_CASE: {
    name: 'To Lower Case',
    description: 'Makes every character in arg-1 lower case.',
    evaluate: ([arg1]) => arg1()?.toString()?.toLowerCase(),
    returnType: 'text',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['text']
  },

  // Matches Regex Expression
  MATCHES_REGEX: {
    name: 'Matches Regex',
    description:
      'Returns true if arg-1 matches the regular expression created from arg-2 and arg-3.  Arg-2 should contain the pattern for the regex expression and arg-3 should contain the flag for the regex expression.  Be sure to escape special characters in the pattern by adding a \\ in front.',
    evaluate: ([arg1, arg2, arg3]) => arg1()?.toString()?.match(new RegExp(arg2()?.toString(), arg3()?.toString())) != null,
    returnType: 'boolean',
    isChainable: false,
    hasItems: false,
    argumentTypes: ['text', 'text', 'text']
  }
};
