const subscriptions = [];

const user = {};

const url = `photoheight/ipl_versions`;

const types = ['users', 'companies', 'server'];

let initialized;

let IPL_Local_Version;

const initialization = new Promise((r) => (initialized = r));

export function setLocalVersion(version) {
  IPL_Local_Version = version;
}

/**
 * Let the version manager know who the user is
 * @param {string} uid
 * @param {string} userGroup
 */
export function setUserInfo(uid, userGroup) {
  let userChanged = false;
  if (uid != user.uid || userGroup != user.userGroup) userChanged = true;
  user.uid = uid;
  user.userGroup = userGroup;
  if (userChanged) {
    subscriptions.forEach(turnOffListeners);
    subscriptions.forEach(setupListeners);
  }
  if (uid && userGroup) initialized();
}

/**
 * This loads the list all versions of IPL accessible to the user based on the configuration in photoheight/ipl_versions (users, companies, or servers can have versions enabled)
 * @param {function} callback - the function to be called with the updated list of versions
 */
export async function getVersions(callback) {
  // Wait for the user to be set with "setUserInfo"
  await initialization;
  // Error if for some reason there is still no user
  if (!user.uid || !user.userGroup) throw new Error('IPL Versions not properly initialized - User not setup');
  const subscription = {
    callback,
    setValue: (type, value) => {
      if (value) delete value._exists;
      subscription[`${type}Value`] = value;
      const values = types.map((type) => subscription[`${type}Value`]);
      if (values.every((value) => value !== undefined)) {
        const result = {};
        Object.assign(result, ...values);
        callback(result);
      }
    },
    usersCallback: (s) => subscription.setValue('users', s.val()),
    companiesCallback: (s) => subscription.setValue('companies', s.val()),
    serverCallback: (s) => subscription.setValue('server', s.val())
  };
  setupListeners(subscription);
  subscriptions.push(subscription);
}

/**
 * Get the correct version of KPLAEngine based on the default engine version set in the models for the job.
 * @param {object} [options]
 * @param {string} [options.jobCreator] - the id of the job's models to check if there is a default engine version set
 * @param {string} [options.jobId] - the id of the job to check if there is a default engine version set in the models (used to get the jobCreator if not passed)
 * @returns {Promise<typeof import('../elements/pole-loading/kpla/KplaEngine.js').KplaEngine>} the correct version of KplaEngine
 */
export async function getKPLAEngine({ jobCreator, jobId } = {}) {
  let engine_version = null;
  /** @type {import('firebase/compat/app').default.database.Database} */
  const realtimeDB = globalThis.FirebaseWorker.database();
  if (IPL_Local_Version) engine_version = IPL_Local_Version;
  else {
    jobCreator ??= (await realtimeDB.ref(`photoheight/jobs/${jobId}/job_creator`).once('value')).val();
    engine_version = (
      await realtimeDB.ref(`photoheight/company_space/${jobCreator}/models/pole_loading/defaults/engine_version`).once('value')
    ).val();
  }

  if (engine_version && engine_version != 'local') {
    const { KplaEngine } = await import(
      `https://storage.googleapis.com/katapult-pro-shared-files/versions/IPL-${engine_version}/KplaEngine.js`
    );
    return KplaEngine;
  } else {
    const { KplaEngine } = await import(`../elements/pole-loading/kpla/KplaEngine.js`);
    KplaEngine._isLocalVersion = true;
    return KplaEngine;
  }
}

function turnOffListeners(subscription) {
  subscription.usersRef?.off?.('value', subscription.usersCallback);
  subscription.companiesRef?.off?.('value', subscription.companiesCallback);
  subscription.serverRef?.off?.('value', subscription.serverCallback);
}

function setupListeners(subscription) {
  subscription.usersRef = FirebaseWorker.database().ref(`${url}/users/${user.uid}`);
  subscription.companiesRef = FirebaseWorker.database().ref(`${url}/companies/${user.userGroup}`);
  subscription.serverRef = FirebaseWorker.database().ref(`${url}/server`);

  // Turn on the listeners
  subscription.usersRef.on('value', subscription.usersCallback);
  subscription.companiesRef.on('value', subscription.companiesCallback);
  subscription.serverRef.on('value', subscription.serverCallback);
}
