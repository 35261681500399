import { KLogicOperators } from './KLogicOperators.js';
import { Path } from './Path.js';

class KLogic {
  constructor({ debug = false }) {
    this.debug = debug;
    this.debugLog = [];
    this.stack = [];
  }

  /** Helper function for correctly parsing literals */
  static parseLiteral(type, value) {
    switch (type) {
      case 'TEXT':
        return String(value);
      case 'NUMBER':
        return Number(value);
      case 'LIST':
      case 'LOOKUP':
        return value;
      case 'BOOLEAN':
      case 'SPECIAL': {
        switch (value) {
          case '$TRUE':
            return true;
          case '$FALSE':
            return false;
          case '$NOW':
            return Date.now();
          case '$NEWLINE':
            return '\n';
          case '$TAB':
            return '\t';
          case '$EMPTY':
            return '';
          case '$NULL':
            return null;
          case '$UNDEFINED':
            return undefined;
        }
      }
    }
  }

  /** Helper function for determining if a key fits the form of a procedure step key */
  static isStepKey = (x) => /step_[0-9]{2}$/.test(x);

  /** Helper function for determining if a key fits the form of an operator argument key */
  static isArgKey = (x) => /arg_[0-9]{2}$/.test(x);

  /** Helper function for determining if a variable is a KLogic block */
  static isKlogicBlock = (x) => {
    // Check that x is an object (bail returning false if not)
    const isObject = typeof x == 'object' && x != null;
    if (!isObject) return false;

    // If the block is explicitly a KLogic block, return true
    if (x.is_klogic_block == true) return true;

    // Otherwise, do some duck-typing
    const hasType = x.type != null;
    const hasProcedureKeys = Object.keys(x).some(KLogic.isStepKey) && x.var_keys != null;
    const hasExpressionKeys = Object.keys(x).some(KLogic.isArgKey) && x.op != null;
    const hasDataKeys = x.path != null;
    const hasLiteralKeys = x.val_type != null && x.val != null;

    return hasType && (hasProcedureKeys || hasExpressionKeys || hasDataKeys || hasLiteralKeys);
  };

  resolve(block, data) {
    // If expression is not an object (or is null)
    if (typeof block != 'object' || block == null) throw new Error('KLogic: All blocks must be objects.');

    // Add this step to the resolution stack (for debugging)
    this.stack.push(block.$key ?? 'root');
    const _path = this.stack.join('/');
    // Define the resolution variable
    let resolution = undefined;

    // Resolve the block based on its type
    switch (block.type) {
      case 'PROCEDURE': {
        const varKeys = block.var_keys;
        const stepsKeys = Object.keys(block).filter(KLogic.isStepKey).sort();

        let procedureData = {};
        for (const [index, stepKey] of Object.entries(stepsKeys)) {
          const dataKey = varKeys[index];
          procedureData[dataKey] = this.resolve({ ...block[stepKey], $key: stepKey }, { ...data, ...procedureData });
        }

        resolution = this.resolve({ ...block.return, $key: 'return' }, { ...data, ...procedureData });
        break;
      }
      case 'EXPRESSION': {
        // Get the expression's operator specifier
        const operatorKey = block.op;
        if (operatorKey == null) throw new Error(`KLogic: Expressions must specify an operator with the "op" property.`);
        // Get the operator definition for this expression
        const operator = KLogicOperators[operatorKey];
        if (operator == null) throw new Error(`KLogic: No definition found for "${operatorKey}".`);
        // Get the expression's item naming scheme
        const itemKey = block.item_key || 'item';

        // Get the expression's arguments as an array of resolvables
        const argKeys = Object.keys(block).filter(KLogic.isArgKey).sort();
        const args = argKeys.map((argKey) => {
          return (_itemData) => {
            let itemData = {};
            if (_itemData != null) {
              itemData[itemKey] = _itemData?.x;
              itemData[`${itemKey}_index`] = _itemData?.i;
              itemData[`${itemKey}_list`] = _itemData?.a;
              if (operator.hasIntermediate) itemData[`${itemKey}_accumulator`] = _itemData?.acc;
            }
            return this.resolve({ ...block[argKey], $key: argKey }, { ...data, ...itemData });
          };
        });

        // If there are no args, throw an error
        if (args.length == 0) throw new Error(`KLogic: Expressions must have at least one argument (of the form "arg_##").`);
        // If we are missing some args (optional args), pad out the array with "empty" args
        const missingArgsCount = operator.argumentTypes.length - args.length;
        if (missingArgsCount > 0) args.push(...Array(missingArgsCount).fill(() => undefined));

        // Finally, evaluate the expression with the given arguments
        resolution = operator.evaluate(args);
        break;
      }
      case 'DATA': {
        const templatedPath = block.path;
        // Replace any templated path parts with their expansion value
        const path = templatedPath.replace(/\$\([-\w.]*\)/g, (x) => Path.get(data, x.slice(2, x.length - 1)));
        resolution = Path.get(data, path);
        break;
      }
      case 'LITERAL': {
        const valType = block.val_type;
        const value = block.val;
        // FEAT (08-24-2022): Handle $() templates
        resolution = KLogic.parseLiteral(valType, value);
        break;
      }
      case 'BLANK': {
        console.warn(
          'KLogic: This statement contains a blank placeholder block. It is being interpreted as null, but it should be explicitly set.'
        );
        resolution = null;
        break;
      }
      default: {
        throw new Error(
          `KLogic: Blocks must specify a type with the "type" property. ` +
            `Expected one of EXPRESSION, DATA, or LITERAL; was ${block.type}.`
        );
      }
    }

    // Log this step and pop off the stack
    if (this.debug) this.debugLog.push({ path: _path, resolved_to: resolution });
    this.stack.pop();

    // Return the resolved value
    return resolution;
  }

  /**
   * Compute a value given an expression and a data object to resolve it against.
   * @param {Object} expression - the expression definition
   * @param {Object} data - data source object
   * @return {any} the computed value
   */
  static compute(expression, data = {}) {
    // Ensure expression is a KLogic block before continuing
    if (!KLogic.isKlogicBlock(expression)) return expression;

    let instance = new KLogic({ debug: false });
    return instance.resolve(expression, data);
  }

  /**
   * Debug an expression with a data object.
   * @param {Object} expression - the expression definition
   * @param {Object} data - data source object
   * @return {Object} an object containing the computed value and debugging information
   */
  static debug(expression, data = {}) {
    // Ensure expression is a KLogic block before continuing
    if (!KLogic.isKlogicBlock(expression)) return expression;

    let instance = new KLogic({ debug: true });
    let res = instance.resolve(expression, data);

    return { log: instance.debugLog, returned: res };
  }
}

export { KLogic };
