import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { DefaultComputedBindings } from '../../modules/DefaultComputedBindings.js';
import '@polymer/paper-progress/paper-progress.js';
import './katapult-contact-info.js';
import '../katapult-elements/katapult-button.js';
import '@polymer/paper-checkbox/paper-checkbox.js';
import '@polymer/paper-radio-group/paper-radio-group.js';
import '@polymer/paper-radio-button/paper-radio-button.js';
import '../paper-table/paper-table.js';
import '../style-modules/paper-table-style.js';
import { GetUserCommunicationOptOutList, UpdateUserCommunicationOptOutList } from '../../modules/UserCommunicationPreferences.js';
import { addFlagListener, removeFlagListener } from '../../modules/FeatureFlags.js';
import { CallCrossServerIdentityAction } from '../../modules/CallCrossServerIdentityAction.js';
import '../../elements/katapult-elements/katapult-icon.js';
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js';

/** @import { PaperCheckboxElement } from '@polymer/paper-checkbox/paper-checkbox.js' */
/** @import { PaperRadioGroupElement } from '@polymer/paper-radio-group/paper-radio-group.js'*/
/** @import { PaperRadioButtonElement } from '@polymer/paper-radio-button/paper-radio-button.js' */

class KatapultAccountSettings extends mixinBehaviors([DefaultComputedBindings], PolymerElement) {
  static get template() {
    return html`
      <style include="paper-table-style">
        :host {
          height: 100%;
          width: 100%;
          flex-direction: column;
          display: flex;
          align-items: center;
          overflow-y: auto;
        }
        user-chip {
          margin: 8px;
          --user-chip-profile: {
            width: 192px;
            height: 192px;
            font-size: 88pt;
          };
        }
        paper-checkbox,
        paper-radio-button {
          --primary-color: var(--secondary-color);
        }
        paper-checkbox {
          --paper-checkbox-label-spacing: 0;
        }
        .group {
          max-width: 100%;
          width: 40rem;
          margin-bottom: 2rem;
        }
        .group .header {
          display: flex;
          align-items: center;
          text-transform: uppercase;
          font-size: 18pt;
          color: var(--primary-text-color-faded);
          margin: 1rem;
        }
        .group .subHeader {
          display: flex;
          align-items: center;
          text-transform: uppercase;
          font-size: 14pt;
          color: var(--primary-text-color-faded);
          margin: 1rem;
        }
        .inputGroup {
          margin: 1rem 0;
        }
        .inputRow {
          display: flex;
        }
        .inputRow paper-input {
          flex: 1;
          margin: 0rem 1rem;
        }
        .inputRow paper-radio-group {
          margin: 0 2rem 0;
        }
        .inputRow paper-radio-button {
          padding-left: 0;
        }
        paper-radio-group paper-radio-button:first-child {
          padding-top: 0;
        }
        .password-meter {
          padding: 0 16px;
          box-sizing: border-box;
        }
        .password-meter[value='0'],
        .password-meter[value='1'] {
          --paper-progress-active-color: var(--paper-red-500);
        }
        .password-meter[value='2'] {
          --paper-progress-active-color: var(--paper-orange-500);
        }
        .password-meter[value='3'] {
          --paper-progress-active-color: var(--paper-light-green-500);
        }
        .password-meter[value='4'] {
          --paper-progress-active-color: var(--paper-green-500);
        }
        .communications-options-table {
          margin: 1rem;
          overflow: hidden;
        }
        .checkbox-cell {
          justify-content: center;
          max-width: 120px;
        }
        .accountOption {
          margin-bottom: 10px;
        }
        .zoomSliderContainer {
          display: flex;
          flex-grow: 1;
          justify-content: left;
          align-items: center;
          margin-top: 10px;
        }
        .zoomSliderContainer span {
          width: 24px;
          text-align: center;
          color: var(--paper-grey-800);
          font-size: 14pt;
        }
        #zoomSlider {
          --paper-slider-active-color: var(--primary-color);
          --paper-slider-knob-color: var(--primary-color);
          --paper-slider-pin-color: var(--primary-color);
        }
      </style>
      <katapult-firebase-worker
        path="photoheight/company_space/[[userGroup]]/user_data/[[uid]]"
        data="{{userData}}"
      ></katapult-firebase-worker>
      <user-chip style="margin-top: 4rem;" uid="[[uid]]" size="200" hide-name=""></user-chip>
      <div class="group">
        <div class="header">
          Contact Info
          <material-icon
            icon="help_outline"
            style="padding-left: 5px; cursor: default;"
            title="The information entered here should only be business contact information, and not personal."
          ></material-icon>
        </div>
        <katapult-contact-info
          id="contactInfo"
          root-company="[[rootCompany]]"
          uid="[[uid]]"
          user-record="[[userRecord]]"
          contact-info-changes="{{contactInfoChanges}}"
        ></katapult-contact-info>
        <iron-collapse opened="[[contactInfoChanges]]">
          <paper-table>
            <paper-row>
              <div style="flex-grow: 1;">[[contactInfoError]]</div>
              <katapult-button color="var(--secondary-color)" callback="saveContactInfo">Save Changes</katapult-button>
            </paper-row>
          </paper-table>
        </iron-collapse>
      </div>
      <div class="group">
        <div class="header">Change Password</div>
        <div class="inputGroup">
          <div class="inputRow">
            <paper-input
              on-value-changed="passwordInputChanged"
              value="{{password}}"
              label="Current Password"
              type="password"
              error-message="[[passwordError]]"
              invalid="[[passwordError]]"
            ></paper-input>
            <div style="flex: 1;">
              <paper-input
                id="newPasswordInput"
                on-value-changed="passwordInputChanged"
                value="{{newPassword}}"
                label="New Password"
                type="password"
                error-message="[[newPasswordError]]"
                invalid="[[newPasswordError]]"
              ></paper-input>
              <div>
                <paper-progress class="password-meter" value$="[[passwordStrength]]" min="-0.2" max="4" step="0.2"></paper-progress>
              </div>
            </div>
            <paper-input
              on-value-changed="passwordInputChanged"
              value="{{confirmNewPassword}}"
              label="Confirm New Password"
              type="password"
              error-message="[[confirmNewPasswordError]]"
              invalid="[[confirmNewPasswordError]]"
            ></paper-input>
          </div>
        </div>
        <iron-collapse opened="[[showChangePasswordButton]]">
          <div class="inputGroup">
            <div class="inputRow">
              <div style="flex-grow: 1;"></div>
              <katapult-button color="var(--secondary-color)" callback="changePassword">Change Password</katapult-button>
            </div>
          </div>
        </iron-collapse>
      </div>
      <div class="group">
        <div class="header">Options</div>
        <div class="inputGroup" style="margin: 1rem;">
          <paper-toggle-button
            class="accountOption"
            data-option-key="use_metric_units"
            checked="[[userData.use_metric_units]]"
            disabled="[[userData.use_decimal_feet]]"
            on-click="updateUserData"
            >Use Metric Units</paper-toggle-button
          >
          <paper-toggle-button
            class="accountOption"
            data-option-key="use_decimal_feet"
            checked="[[userData.use_decimal_feet]]"
            disabled="[[userData.use_metric_units]]"
            on-click="updateUserData"
            >Use Decimal Feet</paper-toggle-button
          >
          <div style="display: flex; flex-direction: row">
            <paper-toggle-button
              class="accountOption"
              data-option-key="right_click_one_click_menu"
              checked="[[userData.right_click_one_click_menu]]"
              on-click="updateUserData"
              >Right Click for Photo Tools Menu</paper-toggle-button
            >
            <sl-tooltip content="When enabled, the Photo Tools menu only appears when right-clicking a photo." placement="right">
              <katapult-icon icon="help" size="small" style="margin-left: var(--sl-spacing-2x-small);"></katapult-icon>
            </sl-tooltip>
          </div>
          <div style="display: flex; flex-direction: row">
            <paper-toggle-button
              class="accountOption"
              data-option-key="dontLinkSpawedWindows"
              checked="[[userData.dontLinkSpawedWindows]]"
              on-click="updateUserData"
              >Open Photos in New Tabs</paper-toggle-button
            >
            <sl-tooltip content="When enabled, photos will open in new tabs instead of reusing a single tab." placement="right">
              <katapult-icon icon="help" size="small" style="margin-left: var(--sl-spacing-2x-small);"></katapult-icon>
            </sl-tooltip>
          </div>
          <div style="display: flex; flex-direction: row">
            <paper-toggle-button
              class="accountOption"
              data-option-key="dock_search_bar"
              checked="[[userData.dock_search_bar]]"
              on-click="updateUserData"
              >Auto-Dock Search Bar</paper-toggle-button
            >
            <sl-tooltip content="When enabled, the Search Bar will dock to the left of the page by default." placement="right">
              <katapult-icon icon="help" size="small" style="margin-left: var(--sl-spacing-2x-small);"></katapult-icon>
            </sl-tooltip>
          </div>
          <paper-toggle-button
            class="accountOption"
            checked="[[userRecord.preferences.poleAppPortalEmailNotifications]]"
            on-change="updatePortalNotificationPreference"
            >Portal Email Notifications</paper-toggle-button
          >
          <template is="dom-if" if="[[computeShowPhotoOptionsForKatapult(userGroup)]]">
            <div style="display: flex; flex-direction: row">
              <paper-toggle-button
                class="accountOption"
                data-option-key="smartCursor"
                checked="[[userData.smartCursor]]"
                on-click="updateUserData"
                >Use Smart Cursor (PerfectCenter) for Calibration</paper-toggle-button
              >
              <sl-tooltip content="Legacy feature to assist in placing calibration anchors." placement="right">
                <katapult-icon icon="help" size="small" style="margin-left: var(--sl-spacing-2x-small);"></katapult-icon>
              </sl-tooltip>
            </div>
            <template is="dom-if" if="[[userData.smartCursor]]">
              <div style="margin-left: var(--sl-spacing-medium)">
                <div class="zoomSliderContainer accountOption">
                  <div style="padding-right: 15px;">Perfect Center pixels to check:</div>
                  <iron-icon icon="image:crop-din"></iron-icon>
                  <paper-slider
                    name="zoom"
                    data-option-key="perfectCenterMinSearch"
                    pin=""
                    snaps=""
                    min="1"
                    max="20"
                    step="1"
                    value="[[computeSliderValueWithFallback(userData.perfectCenterMinSearch, 1, 'perfectCenterMinSearch')]]"
                    on-value-changed="updateUserData"
                  ></paper-slider
                  ><span>[[computeSliderValueWithFallback(userData.perfectCenterMinSearch, 1, 'perfectCenterMinSearch')]]</span>
                </div>
                <div class="zoomSliderContainer">
                  <div style="padding-right: 15px;">Perfect Center sensitivity of search algorithim:</div>
                  <iron-icon icon="image:exposure"></iron-icon>
                  <paper-slider
                    name="zoom"
                    data-option-key="perfectCenterStep"
                    pin=""
                    snaps=""
                    min="0.01"
                    max="0.33"
                    step="0.01"
                    value="[[computeSliderValueWithFallback(userData.perfectCenterStep, 0.01, 'perfectCenterStep')]]"
                    on-value-changed="updateUserData"
                  ></paper-slider>
                  <span>[[computeSliderValueWithFallback(userData.perfectCenterStep, 0.01, 'perfectCenterStep')]]</span>
                </div>
              </div>
            </template>
            <div class="zoomSliderContainer">
              <div style="padding-right: 15px;">Photo Viewer Zoom Sensitvity:</div>
              <iron-icon icon="search"></iron-icon>
              <paper-slider
                name="zoom"
                data-option-key="scroll_sensitivity"
                pin=""
                snaps=""
                min="0"
                max="20"
                step="1"
                value="[[computeSliderValueWithFallback(userData.scroll_sensitivity, 0, 'scroll_sensitivity')]]"
                on-value-changed="updateUserData"
              ></paper-slider>
              <span>[[computeSliderValueWithFallback(userData.scroll_sensitivity, 0, 'scroll_sensitivity')]]</span>
            </div>
          </template>
        </div>
      </div>
      <template is="dom-if" if="[[!isMobile]]">
        <div class="group">
          <div class="header">Communications</div>
          <div style="margin: 1rem;">
            <katapult-button class="accountOption" on-click="enableNativeNotifications"
              >Enable native notifications for Feedback</katapult-button
            >
          </div>

          <div class="subHeader">System</div>
          <paper-table class="communications-options-table" rounded outline alternating>
            <paper-row short>
              <paper-cell></paper-cell>
              <paper-cell class="checkbox-cell">Email</paper-cell>
            </paper-row>
            <template is="dom-if" if="[[isUserCompanyAdmin]]">
              <paper-row>
                <paper-cell>Billing</paper-cell>
                <paper-cell class="checkbox-cell">
                  <paper-checkbox checked disabled></paper-checkbox>
                </paper-cell>
              </paper-row>
            </template>
            <paper-row>
              <paper-cell>Authentication</paper-cell>
              <paper-cell class="checkbox-cell">
                <paper-checkbox checked disabled></paper-checkbox>
              </paper-cell>
            </paper-row>
            <paper-row>
              <paper-cell>Job Sharing</paper-cell>
              <paper-cell class="checkbox-cell">
                <paper-checkbox checked data-email-group-tag="job_sharing" on-click="updateUserCommunicationOptOutList"></paper-checkbox>
              </paper-cell>
            </paper-row>
            <paper-row>
              <paper-cell>Photo Uploads</paper-cell>
              <paper-cell class="checkbox-cell">
                <paper-checkbox checked data-email-group-tag="photo_uploads" on-click="updateUserCommunicationOptOutList"></paper-checkbox>
              </paper-cell>
            </paper-row>
          </paper-table>

          <div class="subHeader">Workflow Management</div>
          <paper-table class="communications-options-table" rounded outline alternating>
            <paper-row short>
              <paper-cell></paper-cell>
              <paper-cell class="checkbox-cell">Email</paper-cell>
            </paper-row>
            <paper-row>
              <paper-cell>Status Updates</paper-cell>
              <paper-cell class="checkbox-cell">
                <paper-checkbox checked data-email-group-tag="app_status" on-click="updateUserCommunicationOptOutList"></paper-checkbox>
              </paper-cell>
            </paper-row>
            <paper-row>
              <paper-cell>Critical Status Updates</paper-cell>
              <paper-cell class="checkbox-cell">
                <paper-checkbox
                  checked
                  data-email-group-tag="app_status_critical"
                  on-click="updateUserCommunicationOptOutList"
                ></paper-checkbox>
              </paper-cell>
            </paper-row>
            <paper-row>
              <paper-cell>Survey Data Available</paper-cell>
              <paper-cell class="checkbox-cell">
                <paper-checkbox checked data-email-group-tag="survey_data" on-click="updateUserCommunicationOptOutList"></paper-checkbox>
              </paper-cell>
            </paper-row>
            <template is="dom-if" if="[[or(isUserPortalAdmin, isUserReviewContractor)]]">
              <paper-row>
                <paper-cell>Admin Notices</paper-cell>
                <paper-cell class="checkbox-cell">
                  <paper-checkbox
                    checked
                    data-email-group-tag="portal_admin"
                    on-click="updateUserCommunicationOptOutList"
                    disabled="[[isUserPortalAdmin]]"
                  ></paper-checkbox>
                </paper-cell>
              </paper-row>
            </template>
          </paper-table>
          <div class="subHeader">Marketing</div>
          <div class="inputGroup">
            <div class="inputRow">
              <paper-radio-group id="marketingEmailsPreferenceRadioGroup" selected="occasional">
                <paper-radio-button name="frequent" on-click="updateUserMarketingEmailsPreference"
                  >Send regular updates on the latest Katapult Pro features</paper-radio-button
                >
                <paper-radio-button checked name="occasional" on-click="updateUserMarketingEmailsPreference"
                  >Only send updates when it relates to my subscription</paper-radio-button
                >
                <paper-radio-button name="none" on-click="updateUserMarketingEmailsPreference"
                  >Don't send me any marketing emails</paper-radio-button
                >
              </paper-radio-group>
            </div>
          </div>
        </div>
      </template>
    `;
  }

  static get is() {
    return 'katapult-account-settings';
  }

  static get properties() {
    return {
      contactInfoChanges: {
        type: Object,
        value: null
      },
      rootCompany: {
        type: String
      },
      password: {
        type: String,
        notify: true
      },
      newPassword: {
        type: String,
        notify: true
      },
      confirmNewPassword: {
        type: String,
        notify: true
      },
      uid: {
        type: String,
        value: null
      },
      userRecord: {
        type: Object,
        value: null
      },
      tooltipIsInvisible: {
        type: Boolean,
        value: true
      },
      userGroup: {
        type: String
      },
      isUserCompanyAdmin: {
        type: Boolean,
        value: false
      },
      isUserPortalAdmin: {
        type: Boolean,
        value: false
      },
      isUserReviewContractor: {
        type: Boolean,
        value: false
      },
      enabledFeatures: {
        type: Object,
        value: () => ({})
      }
    };
  }

  static get observers() {
    return [
      'updateListeners(rootCompany, uid, config.firebaseData.utilityCompany, userGroup)',
      'updateEmailOptOutList(uid)',
      'updateMarketingEmailsPreferenceRadioGroup(rootCompany, uid)'
    ];
  }

  constructor() {
    super();
    this.inputsDisabled = false;
    this.isMobile = globalThis.isMobile;
  }

  connectedCallback() {
    super.connectedCallback();

    // TODO: Remove this feature flag check once CSI is enabled for all users
    this.crossServerIdentityFlagListener = addFlagListener('cross_server_identity', (enabled) => {
      this.set('enabledFeatures.cross_server_identity', enabled);
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (this.isUserPortalAdminListener) {
      globalThis.FirebaseWorker.database()
        .ref(`users/${this.rootCompany}/${this.uid}/roles/pole_app_portal/pole_app_portal_admin`)
        .off('value', this.isUserPortalAdminListener);
      this.isUserPortalAdminListener = null;
    }

    if (this.isUserReviewContractorListener) {
      globalThis.FirebaseWorker.database()
        .ref(
          `photoheight/company_space/${globalThis.config.firebaseData.utilityCompany}/portal_config/user_options/review_contractors/${this.userGroup}`
        )
        .off('value', this.isUserReviewContractorListener);
      this.isUserReviewContractorListener = null;
    }

    removeFlagListener('cross_server_identity', this.crossServerIdentityFlagListener);
  }

  ready() {
    super.ready();
  }

  updateListeners() {
    if (this.rootCompany && this.uid) {
      // Set up listener for the user's portal admin status
      if (!this.isUserPortalAdminListener) {
        this.isUserPortalAdminListener = globalThis.FirebaseWorker.database()
          .ref(`users/${this.rootCompany}/${this.uid}/roles/pole_app_portal/pole_app_portal_admin`)
          .on('value', (s) => {
            this.isUserPortalAdmin = Boolean(s.val());
          });
      }
    }

    if (this.userGroup) {
      // Set up listener for the user's review contractor status
      if (!this.isUserReviewContractorListener) {
        this.isUserReviewContractorListener = globalThis.FirebaseWorker.database()
          .ref(
            `photoheight/company_space/${globalThis.config.firebaseData.utilityCompany}/portal_config/user_options/review_contractors/${this.userGroup}`
          )
          .on('value', (s) => {
            this.isUserReviewContractor = Boolean(s.val());
          });
      }
    }
  }

  /**
   * Updates the email opt-out list for the current user.
   * This method retrieves the user's email opt-out list and updates the corresponding
   * checkboxes in the UI to reflect the opt-out status.
   * @returns {Promise<void>} A promise that resolves when the email opt-out list has been updated.
   */
  async updateEmailOptOutList() {
    if (this.uid) {
      // Get the user's email opt-out list
      const optOutList = await GetUserCommunicationOptOutList(this.uid, 'email');

      // Loop through each email group tag and update the corresponding checkbox
      for (const emailGroupTag of optOutList) {
        const checkboxElement = /** @type {PaperCheckboxElement|undefined} */ (
          this.shadowRoot?.querySelector(`[data-email-group-tag="${emailGroupTag}"]`)
        );
        if (checkboxElement) checkboxElement.checked = false;
      }
    }
  }

  /**
   * Updates the marketing emails preference radio group based on the user's current preference.
   * This method retrieves the user's marketing emails preference from the Firebase database
   * and sets the corresponding radio button in the UI.
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   */
  async updateMarketingEmailsPreferenceRadioGroup() {
    if (this.rootCompany && this.uid) {
      // Get the user's marketing emails preference
      const marketingEmailsPreference = await globalThis.FirebaseWorker.database()
        .ref(`users/${this.rootCompany}/${this.uid}/preferences/communication/email/marketing_frequency`)
        .once('value')
        .then((s) => s.val());
      if (!marketingEmailsPreference) return;

      // Set the selected radio button
      const radioButtonElement = /** @type {PaperRadioGroupElement} */ (
        this.shadowRoot?.querySelector('#marketingEmailsPreferenceRadioGroup')
      );
      if (!radioButtonElement) throw new Error('No radio group element found.');
      radioButtonElement.selected = marketingEmailsPreference;
    }
  }

  passwordInputChanged(e) {
    this.showChangePasswordButton = true;
    if (e.currentTarget.id == 'newPasswordInput') katapultAuth._checkPasswordStrength(e);
  }

  async changePassword() {
    await katapultAuth._changePassword(true);
    if (!this.passwordError && !this.newPasswordError && !this.confirmNewPasswordError) {
      this.password = ''; // Clear password since setting it from account settings.
      this.showChangePasswordButton = false;
    }
  }

  async saveContactInfo() {
    await this.$.contactInfo.saveContactInfo();
  }

  /**
   * Event handler that updates the user's communication opt-out list.
   * @param {Event} event - The event object from the user's interaction.
   * @returns {Promise<void>} - A promise that resolves when the update is complete.
   */
  async updateUserCommunicationOptOutList(event) {
    const checkboxElement = /** @type {PaperCheckboxElement} */ (event.target);
    const checked = Boolean(checkboxElement.checked);
    const emailGroupTag = checkboxElement.dataset.emailGroupTag;
    if (!emailGroupTag) throw new Error('No "data-email-group-tag" attribute found on checkbox element.');

    // Update the user's communication opt-out list. Note that since this is an opt-out list, we negate the checked value.
    await UpdateUserCommunicationOptOutList(this.uid, 'email', { [emailGroupTag]: !checked });
  }

  /**
   * Event handler that updates the user's marketing emails preference.
   * @param {Event} event - The event triggered by the user's interaction with the radio button.
   * @returns {Promise<void>} A promise that resolves when the preference has been updated in the database.
   */
  async updateUserMarketingEmailsPreference(event) {
    const radioButton = /** @type {PaperRadioButtonElement} */ (event.target);
    const selectedValue = radioButton.name;

    await globalThis.FirebaseWorker.database()
      .ref(`users/${this.rootCompany}/${this.uid}/preferences/communication/email/marketing_frequency`)
      .set(selectedValue);
  }

  /**
   * Enable notifications for feedback conversations
   */
  enableNativeNotifications() {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification');
    }
    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      new Notification("You'll now get notifications for feedback conversations!");
    }
    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          new Notification("You'll now get notifications for feedback conversations!");
        }
      });
    }
  }

  /**
   * Updates the database with the new value for the option
   *
   * Note: Each option has a corresponding type.  If none is provided, it is assumed that the option is a toggle.
   * @param {object} e - The event data for when the user toggled the option
   * @returns {Promise<void>} A promise that resolves when the data is updated.
   */
  async updateUserData(e) {
    // check for the option key, root company, and user id
    const optionKey = e.currentTarget?.dataset?.optionKey;
    if (!optionKey || !this.rootCompany || !this.uid) return;

    // Get the value (default)
    let value = e.currentTarget?.checked;
    // Get the value differently if the optionKey uses a slider
    const sliderKeys = ['perfectCenterMinSearch', 'perfectCenterStep', 'scroll_sensitivity'];
    if (sliderKeys.includes(optionKey)) {
      value = e.detail?.value;
      // special case for scroll_sensitivity slider
      if (value == 0 && optionKey == 'scroll_sensitivity') value = null;
    }
    // check if the value exists and if it is different than the current value
    if (value === undefined || value == this.userData?.[optionKey]) return;

    // update the user data
    const updatePath = `photoheight/company_space/${this.rootCompany}/user_data/${this.uid}`;
    await FirebaseWorker.ref(updatePath).update({ [optionKey]: value });

    // Sync relevant options across databases with CSI
    const optionKeysToSync = ['right_click_one_click_menu'];
    if (optionKeysToSync.includes(optionKey)) await this.syncUserActionOption(optionKey);
  }

  /**
   * Handles calling the central syncing function to sync the right click one click menu user setting across all servers
   * Updates the satellite server data with the new menu configuration.
   * @param {string} optionKey - The key of the option to sync across servers
   * @returns {Promise<void>} A promise that resolves when the data is synced.
   */
  async syncUserActionOption(optionKey) {
    // TODO: Remove this feature flag check once CSI is enabled for all users
    if (optionKey == null || !this.enabledFeatures?.cross_server_identity) return;

    await CallCrossServerIdentityAction(firebase.auth(), 'SYNC_DATA_FROM_SATELLITE_SERVER', {
      satelliteDataId: optionKey,
      serverId: config.appName,
      userId: this.uid
    });
  }

  /**
   * Determines when to show the photo options.  They should only be shown if the user's active company is katapult.
   * @returns {boolean} Whether to show the photo options
   */
  computeShowPhotoOptionsForKatapult() {
    return this.userGroup == 'katapult';
  }

  /**
   * Provide a fallback if slider values are not set so that the slider works (they break if their value is undefined)
   * @param {number} sliderValue - The value of the slider
   * @param {number} fallback - The fallback value
   * @returns {number} The value of the slider
   */
  computeSliderValueWithFallback(sliderValue, fallback) {
    return sliderValue ?? fallback;
  }

  /**
   * Handles the toggle event for portal email notifications by setting the toggle's value in the database
   * @param {object} e - Event data from the toggle event
   */
  async updatePortalNotificationPreference(e) {
    // get the new value of email and ensure that it exists and is different than the current value
    const newEmailNotificationValue = e.currentTarget.checked;
    if (
      newEmailNotificationValue == null ||
      newEmailNotificationValue == this.userRecord?.preferences?.poleAppPortalEmailNotifications ||
      !this.uid
    )
      return;
    // update the user's email notification preference in the database
    await FirebaseWorker.ref(`users/${this.rootCompany}/${this.uid}/preferences/poleAppPortalEmailNotifications`).set(
      newEmailNotificationValue
    );
  }
}
window.customElements.define(KatapultAccountSettings.is, KatapultAccountSettings);
